#top-header {
    min-height: 61px;
}

header {
    #main-nav {
        >ul {
            /*
            display: flex;
            border-right: none;
            position: absolute;
            left: 45%;
            transform: translateX(-45%);
            float: none;
            li>ul {
                border: none;
            }
            li {
                a {
                    border-left: none;
                    white-space: nowrap;
                }
            }

             */
        }
        .start:not(.staff) {
            display: none;
        }
    }
    .text-wrap {
        display: block;
        float: right;
        p {
            font-family: 'BrandonGrotesque';
            color: #4a515b;
            @include font-size(18px);
            margin: 12px 0 0 0;
        }
        a {
            @include font-size(36px);
            font-family: "BrandonGrotesque";
            color: rgb(26, 64, 114);
            font-weight: bold;
            text-transform: uppercase;
            line-height: 1.2;
            text-decoration: none;
        }
    }
}

header.active {
    #main-nav {
        .start {
            display: block;
        }
    }
    .text-wrap {
        display: none;
    }
}

.full-logo {
    width: 115px;
    height: auto;
}

@media only screen and (max-device-width: 1025px) {
    /*
    header {
        background: salmon !important;
        #main-nav {
            ul {
                li {
                    a {
                        padding-right: 10px;
                        padding-left: 10px;
                    }
                }
            }
        }
    }

     */
}

@media only screen and (max-device-width: 767px) {
    header {
        .text-wrap {
            display: none;
        }
    }
}