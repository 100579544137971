@font-face {
  font-family: "brandon";
  src: url("../../assets/css/fonts/brandon/brandon_bld-webfont.eot");
  src: url("../../assets/css/fonts/brandon/brandon_bld-webfont.eot?#iefix") format("embedded-opentype"), url("../../assets/css/fonts/brandon/brandon_bld-webfont.woff") format("woff"), url("../../assets/css/fonts/brandon/brandon_bld-webfont.ttf") format("truetype"), url("../../assets/css/fonts/brandon/brandon_bld-webfont.svg#brandon_grotesquebold") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "SourceSansPro";
  src: url("../../assets/css/fonts/SourceSansPro-Regular.otf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Brandon_light";
  src: url("../../assets/css/fonts/brandon/Brandon_light.otf"), url("../../assets/css/fonts/brandon/Brandon_light.otf") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "BrandonGrotesque";
  src: url("../../assets/css/fonts/brandon/Brandon_bld.otf"), url("../../assets/css/fonts/brandon/Brandon_bld.otf") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "brandon";
  src: url("../../assets/css/fonts/brandon/brandon_blk-webfont.eot");
  src: url("../../assets/css/fonts/brandon/brandon_blk-webfont.eot?#iefix") format("embedded-opentype"), url("../../assets/css/fonts/brandon/brandon_blk-webfont.woff") format("woff"), url("../../assets/css/fonts/brandon/brandon_blk-webfont.ttf") format("truetype"), url("../../assets/css/fonts/brandon/brandon_blk-webfont.svg#brandon_grotesqueblack") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "brandon";
  src: url("../../assets/css/fonts/brandon/brandon_reg-webfont.eot");
  src: url("../../assets/css/fonts/brandon/brandon_reg-webfont.eot?#iefix") format("embedded-opentype"), url("../../assets/css/fonts/brandon/brandon_reg-webfont.woff") format("woff"), url("../../assets/css/fonts/brandon/brandon_reg-webfont.ttf") format("truetype"), url("../../assets/css/fonts/brandon/brandon_reg-webfont.svg#brandon_grotesque_regularRg") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "sourceSemiBold";
  src: url("../../assets/css/fonts/source/SourceSansPro-Semibold.ttf");
  src: url("../../assets/css/fonts/source/SourceSansPro-Semibold.ttf?#iefix") format("embedded-opentype"), url("../../assets/css/fonts/source/SourceSansPro-Semibold.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "sourceSemiItallic";
  src: url("../../assets/css/fonts/source/SourceSansPro-SemiboldItalic.ttf");
  src: url("../../assets/css/fonts/source/SourceSansPro-SemiboldItalic.ttf?#iefix") format("embedded-opentype"), url("../../assets/css/fonts/source/SourceSansPro-SemiboldItalic.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "source";
  src: url("../../assets/css/fonts/source/sourcesanspro-bold-webfont.eot");
  src: url("../../assets/css/fonts/source/sourcesanspro-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../../assets/css/fonts/source/sourcesanspro-bold-webfont.woff") format("woff"), url("../../assets/css/fonts/source/sourcesanspro-bold-webfont.ttf") format("truetype"), url("../../assets/css/fonts/source/sourcesanspro-bold-webfont.svg#source_sans_probold") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "source";
  src: url("../../assets/css/fonts/source/sourcesanspro-italic-webfont.eot");
  src: url("../../assets/css/fonts/source/sourcesanspro-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../../assets/css/fonts/source/sourcesanspro-italic-webfont.woff") format("woff"), url("../../assets/css/fonts/source/sourcesanspro-italic-webfont.ttf") format("truetype"), url("../../assets/css/fonts/source/sourcesanspro-italic-webfont.svg#source_sans_proitalic") format("svg");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "source";
  src: url("../../assets/css/fonts/source/sourcesanspro-regular-webfont.eot");
  src: url("../../assets/css/fonts/source/sourcesanspro-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../../assets/css/fonts/source/sourcesanspro-regular-webfont.woff") format("woff"), url("../../assets/css/fonts/source/sourcesanspro-regular-webfont.ttf") format("truetype"), url("../../assets/css/fonts/source/sourcesanspro-regular-webfont.svg#source_sans_proregular") format("svg");
  font-weight: 300;
  font-style: normal;
}
/* font-family: "MyriadProRegular"; */
@font-face {
  font-family: "MyriadProRegular";
  src: url("../../assets/css/fonts/MyriadProRegular/MyriadProRegular.eot");
  src: url("../../assets/css/fonts/MyriadProRegular/MyriadProRegular.eot?#iefix") format("embedded-opentype"), url("../../assets/css/fonts/MyriadProRegular/MyriadProRegular.woff") format("woff"), url("../../assets/css/fonts/MyriadProRegular/MyriadProRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "MyriadProBold"; */
@font-face {
  font-family: "MyriadProBold";
  src: url("../../assets/css/fonts/MyriadProBold/MyriadProBold.eot");
  src: url("../../assets/css/fonts/MyriadProBold/MyriadProBold.eot?#iefix") format("embedded-opentype"), url("../../assets/css/fonts/MyriadProBold/MyriadProBold.woff") format("woff"), url("../../assets/css/fonts/MyriadProBold/MyriadProBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "MyriadProItalic"; */
@font-face {
  font-family: "MyriadProItalic";
  src: url("../../assets/css/fonts/MyriadProItalic/MyriadProItalic.eot");
  src: url("../../assets/css/fonts/MyriadProItalic/MyriadProItalic.eot?#iefix") format("embedded-opentype"), url("../../assets/css/fonts/MyriadProItalic/MyriadProItalic.woff") format("woff"), url("../../assets/css/fonts/MyriadProItalic/MyriadProItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "MyriadProSemiBold"; */
@font-face {
  font-family: "MyriadProSemiBold";
  src: url("../../assets/css/fonts/MyriadProSemiBold/MyriadProSemiBold.eot");
  src: url("../../assets/css/fonts/MyriadProSemiBold/MyriadProSemiBold.eot?#iefix") format("embedded-opentype"), url("../../assets/css/fonts/MyriadProSemiBold/MyriadProSemiBold.woff") format("woff"), url("../../assets/css/fonts/MyriadProSemiBold/MyriadProSemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "MyriadProBoldItalic"; */
@font-face {
  font-family: "MyriadProBoldItalic";
  src: url("../../assets/css/fonts/MyriadProBoldItalic/MyriadProBoldItalic.eot");
  src: url("../../assets/css/fonts/MyriadProBoldItalic/MyriadProBoldItalic.eot?#iefix") format("embedded-opentype"), url("../../assets/css/fonts/MyriadProBoldItalic/MyriadProBoldItalic.woff") format("woff"), url("../../assets/css/fonts/MyriadProBoldItalic/MyriadProBoldItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "MyriadProSemiBoldItalic"; */
@font-face {
  font-family: "MyriadProSemiBoldItalic";
  src: url("../../assets/css/fonts/MyriadProSemiBoldItalic/MyriadProSemiBoldItalic.eot");
  src: url("../../assets/css/fonts/MyriadProSemiBoldItalic/MyriadProSemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("../../assets/css/fonts/MyriadProSemiBoldItalic/MyriadProSemiBoldItalic.woff") format("woff"), url("../../assets/css/fonts/MyriadProSemiBoldItalic/MyriadProSemiBoldItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* Center contents with flexbox */
.date-wrap, .timeline-item, .timeline-container, .intro-wrap, .blue-cta-invert, .blue-cta, .hero-cta, .orange-cta-invert, .orange-cta {
  display: flex;
  justify-content: center;
  align-items: center;
}

body {
  font: 1.2em "Source", sans-serif;
}

/* CTA Styles */
.orange-cta {
  text-transform: uppercase;
  cursor: pointer;
  color: #ff5800;
  border: 2px solid #ff5800;
  font-weight: bold;
  border-radius: 3px;
  text-decoration: none;
  display: inline-flex;
  flex: 1;
  font-size: 0.9375rem;
  padding-top: 1.125rem;
  padding-right: calc(1.26875rem + 0.15vw);
  padding-bottom: 1.125rem;
  padding-left: calc(1.26875rem + 0.15vw);
  margin-top: calc(1.3625rem + 0.9vw);
  transition: all 0.2s ease-in-out;
  background: #fff;
}
@media (min-width: 1800px) {
  .orange-cta {
    padding-right: 1.4375rem;
  }
}
@media (min-width: 1800px) {
  .orange-cta {
    padding-left: 1.4375rem;
  }
}
@media (min-width: 1800px) {
  .orange-cta {
    margin-top: 2.375rem;
  }
}

.orange-cta:hover {
  background: #ff5800;
  color: #fff;
}

.orange-cta-invert {
  text-transform: uppercase;
  cursor: pointer;
  color: #fff;
  border: 2px solid #ff5800;
  font-weight: bold;
  border-radius: 3px;
  text-decoration: none;
  display: inline-flex;
  flex: 1;
  font-size: 0.9375rem;
  padding-top: 1.125rem;
  padding-right: calc(1.26875rem + 0.15vw);
  padding-bottom: 1.125rem;
  padding-left: calc(1.26875rem + 0.15vw);
  margin-top: calc(1.3625rem + 0.9vw);
  transition: all 0.2s ease-in-out;
  background: #ff5800;
}
@media (min-width: 1800px) {
  .orange-cta-invert {
    padding-right: 1.4375rem;
  }
}
@media (min-width: 1800px) {
  .orange-cta-invert {
    padding-left: 1.4375rem;
  }
}
@media (min-width: 1800px) {
  .orange-cta-invert {
    margin-top: 2.375rem;
  }
}

.orange-cta-invert:hover {
  background: #fff;
  color: #ff5800;
}

.hero-cta {
  cursor: pointer;
  color: #fff;
  border: 2px solid #ff5800;
  font-weight: bold;
  border-radius: 8px;
  text-decoration: none;
  display: inline-flex;
  flex: 1;
  font-size: 1.125rem;
  padding-top: calc(1.275rem + 0.2vw);
  padding-right: 1.125rem;
  padding-bottom: calc(1.275rem + 0.2vw);
  padding-left: 1.125rem;
  margin-top: calc(1.3625rem + 0.9vw);
  transition: all 0.2s ease-in-out;
  background: #ff5800;
}
@media (min-width: 1800px) {
  .hero-cta {
    padding-top: 1.5rem;
  }
}
@media (min-width: 1800px) {
  .hero-cta {
    padding-bottom: 1.5rem;
  }
}
@media (min-width: 1800px) {
  .hero-cta {
    margin-top: 2.375rem;
  }
}
.hero-cta img {
  margin-right: 7px;
}

.hero-cta:hover {
  /* background: #fff; */
  background: #ff7e00;
  border: 2px solid #ff7e00;
  /* color: #ff5800; */
}

.blue-cta {
  text-transform: uppercase;
  cursor: pointer;
  color: #0e7ac4;
  border: 2px solid #0e7ac4;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  flex-shrink: 0;
  flex: 0;
  font-size: 0.9375rem;
  padding-top: 1.125rem;
  padding-right: calc(1.3375rem + 0.7vw);
  padding-bottom: 1.125rem;
  padding-left: calc(1.3375rem + 0.7vw);
  margin-top: calc(1.3625rem + 0.9vw);
  transition: all 0.2s ease-in-out;
  border-radius: 3px;
  background: #fff;
}
@media (min-width: 1800px) {
  .blue-cta {
    padding-right: 2.125rem;
  }
}
@media (min-width: 1800px) {
  .blue-cta {
    padding-left: 2.125rem;
  }
}
@media (min-width: 1800px) {
  .blue-cta {
    margin-top: 2.375rem;
  }
}

.blue-cta:hover {
  color: #fff;
  border: 2px solid #ff5800;
  background: #ff5800;
}

.blue-cta-invert {
  text-transform: uppercase;
  cursor: pointer;
  color: #fff;
  border: 2px solid #0e7ac4;
  font-weight: bold;
  text-decoration: none;
  display: inline-flex;
  flex: 1 0 auto;
  font-size: 0.9375rem;
  padding-top: 1.125rem;
  padding-right: calc(1.3375rem + 0.7vw);
  padding-bottom: 1.125rem;
  padding-left: calc(1.3375rem + 0.7vw);
  margin-top: calc(1.3625rem + 0.9vw);
  transition: all 0.2s ease-in-out;
  border-radius: 3px;
  background: #0e7ac4;
}
@media (min-width: 1800px) {
  .blue-cta-invert {
    padding-right: 2.125rem;
  }
}
@media (min-width: 1800px) {
  .blue-cta-invert {
    padding-left: 2.125rem;
  }
}
@media (min-width: 1800px) {
  .blue-cta-invert {
    margin-top: 2.375rem;
  }
}

.blue-cta-invert:hover {
  background: #fff;
  color: #0e7ac4;
}

@media only screen and (max-device-width: 767px) {
  .blue-cta-invert {
    width: 100%;
    box-sizing: border-box;
  }
}
#top-header {
  min-height: 61px;
}

header #main-nav > ul {
  /*
  display: flex;
  border-right: none;
  position: absolute;
  left: 45%;
  transform: translateX(-45%);
  float: none;
  li>ul {
      border: none;
  }
  li {
      a {
          border-left: none;
          white-space: nowrap;
      }
  }

   */
}
header #main-nav .start:not(.staff) {
  display: none;
}
header .text-wrap {
  display: block;
  float: right;
}
header .text-wrap p {
  font-family: "BrandonGrotesque";
  color: #4a515b;
  font-size: 1.125rem;
  margin: 12px 0 0 0;
}
header .text-wrap a {
  font-size: calc(1.35rem + 0.8vw);
  font-family: "BrandonGrotesque";
  color: #1a4072;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2;
  text-decoration: none;
}
@media (min-width: 1800px) {
  header .text-wrap a {
    font-size: 2.25rem;
  }
}

header.active #main-nav .start {
  display: block;
}
header.active .text-wrap {
  display: none;
}

.full-logo {
  width: 115px;
  height: auto;
}

@media only screen and (max-device-width: 1025px) {
  /*
  header {
      background: salmon !important;
      #main-nav {
          ul {
              li {
                  a {
                      padding-right: 10px;
                      padding-left: 10px;
                  }
              }
          }
      }
  }

   */
}
@media only screen and (max-device-width: 767px) {
  header .text-wrap {
    display: none;
  }
}
.new-section-header {
  font-size: calc(1.4375rem + 1.5vw);
  margin: 0 auto;
  font-family: "BrandonGrotesque";
}
@media (min-width: 1800px) {
  .new-section-header {
    font-size: 3.125rem;
  }
}

.blog-single .single-blog2 {
  margin-top: 60px;
}

body {
  display: block !important;
}

.home-banner {
  background: url("../../assets/img/home/new-home-hero.png") no-repeat;
  background-size: cover;
  width: 100%;
  height: 902px;
}

.hero-text {
  text-align: center;
  padding-top: 105px;
}
.hero-text h1 {
  font-size: calc(1.425rem + 1.4vw);
  font-family: "BrandonGrotesque";
  color: #4a515b;
  line-height: 1.2;
}
@media (min-width: 1800px) {
  .hero-text h1 {
    font-size: 3rem;
  }
}
.hero-text p {
  font-size: calc(1.275rem + 0.2vw);
  font-family: "brandon";
  color: #4a515b;
  line-height: 1.2;
  text-align: center;
}
@media (min-width: 1800px) {
  .hero-text p {
    font-size: 1.5rem;
  }
}
.hero-text .cta-wrap {
  width: 100%;
  text-align: center;
  /* margin-top: 59px; */
}
.hero-text .cta-wrap a:first-child {
  /* margin-right: 20px; */
}

.header-wrap {
  width: 66.667%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.intro-wrap {
  background: #1a4072;
  color: #fff;
  flex-direction: column;
  padding-top: calc(1.5625rem + 2.5vw);
  padding-right: calc(1.5625rem + 2.5vw);
  padding-bottom: 0;
  padding-left: calc(1.5625rem + 2.5vw);
  padding-bottom: 470px;
}
@media (min-width: 1800px) {
  .intro-wrap {
    padding-top: 4.375rem;
  }
}
@media (min-width: 1800px) {
  .intro-wrap {
    padding-right: 4.375rem;
  }
}
@media (min-width: 1800px) {
  .intro-wrap {
    padding-left: 4.375rem;
  }
}
.intro-wrap p {
  margin-top: calc(1.3625rem + 0.9vw);
  font-size: calc(1.275rem + 0.2vw);
}
@media (min-width: 1800px) {
  .intro-wrap p {
    margin-top: 2.375rem;
  }
}
@media (min-width: 1800px) {
  .intro-wrap p {
    font-size: 1.5rem;
  }
}

.testimonial-slider-wrap {
  width: 100%;
  position: relative;
  background: #f3f6fb;
}
.testimonial-slider-wrap .testimonial-slider {
  margin-top: -400px;
  padding-bottom: calc(1.5rem + 2vw);
}
@media (min-width: 1800px) {
  .testimonial-slider-wrap .testimonial-slider {
    padding-bottom: 3.75rem;
  }
}
.testimonial-slider-wrap .testimonial-slider .slick-track {
  display: flex !important;
  padding-bottom: 13px;
  /* Prevent cut off of item box shadow */
}
.testimonial-slider-wrap .testimonial-slider .slick-slide {
  height: inherit !important;
}
.testimonial-slider-wrap .testimonial-slider .slick-slide > div, .testimonial-slider-wrap .testimonial-slider .slick-slide .slide {
  height: 100%;
}
.testimonial-slider-wrap .tst-slider-item {
  margin: 0 15px;
  box-shadow: 1px 1.732px 9.8px 0.2px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 0.9375rem;
}
.testimonial-slider-wrap .tst-slider-item .hero {
  height: 280px;
  background-position: center top;
  background-size: cover;
}
.testimonial-slider-wrap .tst-slider-item .content {
  padding-top: calc(1.35rem + 0.8vw);
  padding-right: calc(1.35rem + 0.8vw);
  padding-bottom: calc(1.35rem + 0.8vw);
  padding-left: calc(1.35rem + 0.8vw);
  background: #fff;
  margin-bottom: 0;
  flex: 1;
}
@media (min-width: 1800px) {
  .testimonial-slider-wrap .tst-slider-item .content {
    padding-top: 2.25rem;
  }
}
@media (min-width: 1800px) {
  .testimonial-slider-wrap .tst-slider-item .content {
    padding-right: 2.25rem;
  }
}
@media (min-width: 1800px) {
  .testimonial-slider-wrap .tst-slider-item .content {
    padding-bottom: 2.25rem;
  }
}
@media (min-width: 1800px) {
  .testimonial-slider-wrap .tst-slider-item .content {
    padding-left: 2.25rem;
  }
}
.testimonial-slider-wrap .tst-slider-item .words {
  font-size: calc(1.275rem + 0.2vw);
  color: #4a515b;
  line-height: 1.25;
  margin-bottom: 1.25rem;
  font-family: "BrandonGrotesque";
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
@media (min-width: 1800px) {
  .testimonial-slider-wrap .tst-slider-item .words {
    font-size: 1.5rem;
  }
}
.testimonial-slider-wrap .tst-slider-item .desc {
  font-size: 1.125rem;
  font-family: "Brandon_light";
  color: #4a515b;
  line-height: 1.333;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}
.testimonial-slider-wrap .tst-slider-item .name {
  font-size: 1.125rem;
  font-family: "BrandonGrotesque";
  color: #353535;
  font-weight: bold;
  line-height: 1.2;
  margin: 25px auto 12px;
}
.testimonial-slider-wrap .slick-list {
  /* padding: 0 12.5%; */
}

.testimonial-slider-wrap::before {
  content: "";
  background: #1a4072;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 330px;
}

.card-wrap {
  background: #f3f6fb;
  color: #4a515b;
  text-align: center;
  padding-top: 0;
  padding-right: calc(1.55rem + 2.4vw);
  padding-bottom: 0;
  padding-left: calc(1.55rem + 2.4vw);
}
@media (min-width: 1800px) {
  .card-wrap {
    padding-right: 4.25rem;
  }
}
@media (min-width: 1800px) {
  .card-wrap {
    padding-left: 4.25rem;
  }
}
.card-wrap h2 {
  color: #4a515b;
}
.card-wrap > p {
  margin-bottom: calc(1.48125rem + 1.85vw);
}
@media (min-width: 1800px) {
  .card-wrap > p {
    margin-bottom: 3.5625rem;
  }
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: min-content auto;
  grid-gap: 38px 38px;
  padding-left: 16.611111111%;
  padding-right: 16.611111111%;
  padding-bottom: calc(1.68125rem + 3.45vw);
}
@media (min-width: 1800px) {
  .card-grid {
    padding-bottom: 5.5625rem;
  }
}

.card-item {
  box-shadow: 1px 1.732px 9.8px 0.2px rgba(0, 0, 0, 0.15);
  padding-top: calc(1.4375rem + 1.5vw);
  padding-right: calc(1.4rem + 1.2vw);
  padding-bottom: calc(1.4375rem + 1.5vw);
  padding-left: calc(1.4rem + 1.2vw);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
}
@media (min-width: 1800px) {
  .card-item {
    padding-top: 3.125rem;
  }
}
@media (min-width: 1800px) {
  .card-item {
    padding-right: 2.75rem;
  }
}
@media (min-width: 1800px) {
  .card-item {
    padding-bottom: 3.125rem;
  }
}
@media (min-width: 1800px) {
  .card-item {
    padding-left: 2.75rem;
  }
}
.card-item img {
  margin-bottom: calc(1.34375rem + 0.75vw);
  /*width: 100%;
  @include rfs(110px,
  max-width);*/
  margin: 0 auto;
  height: 120px;
}
@media (min-width: 1800px) {
  .card-item img {
    margin-bottom: 2.1875rem;
  }
}
.card-item > p.card-title {
  color: #4a515b;
  font-size: calc(1.275rem + 0.2vw);
  font-weight: bold;
  margin-bottom: calc(1.275rem + 0.2vw);
}
@media (min-width: 1800px) {
  .card-item > p.card-title {
    font-size: 1.5rem;
  }
}
@media (min-width: 1800px) {
  .card-item > p.card-title {
    margin-bottom: 1.5rem;
  }
}
.card-item > p.card-desc {
  color: #4a515b;
  font-size: 1.125rem;
  margin: 0 auto;
  font-family: "brandon";
  font-weight: 300;
  line-height: 1.333;
}
.card-item .cta {
  flex: 0;
}

.video-inline {
  display: flex;
  padding: 0 16.277777777% 0 19.444444444%;
  padding-bottom: calc(1.7625rem + 4.1vw);
  padding-top: calc(1.7625rem + 4.1vw);
}
@media (min-width: 1800px) {
  .video-inline {
    padding-bottom: 6.375rem;
  }
}
@media (min-width: 1800px) {
  .video-inline {
    padding-top: 6.375rem;
  }
}

.video-player {
  background: url("../../assets/img/boots-to-roots/mark_peer_default.png") no-repeat;
  background-size: contain;
  width: calc(5.125rem + 31vw);
  height: calc(3.79375rem + 20.35vw);
  margin: 0 auto;
  cursor: pointer;
  position: relative;
  -webkit-transition: 0.2s all ease-in;
  -o-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(5.125rem + 31vw);
}
@media (min-width: 1800px) {
  .video-player {
    width: 40rem;
  }
}
@media (min-width: 1800px) {
  .video-player {
    height: 26.6875rem;
  }
}
@media (min-width: 1800px) {
  .video-player {
    flex-basis: 40rem;
  }
}

.video-player::before {
  content: "";
  position: absolute;
  border: 11px solid #1a4072;
  top: 50px;
  left: -39px;
  right: 50px;
  bottom: -39px;
  z-index: -1;
}

.video-player .video {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.video-player .video iframe {
  width: 100%;
  height: 100%;
}

.preload {
  background: url("../../assets/img/boots-to-roots/mark_peer_hover.png") no-repeat -9999999px -99999999px;
}

.video-player:hover {
  background-image: url("../../assets/img/boots-to-roots/mark_peer_hover.png");
  cursor: pointer;
}

.video-text-wrap {
  padding-left: calc(1.775rem + 4.2vw);
}
@media (min-width: 1800px) {
  .video-text-wrap {
    padding-left: 6.5rem;
  }
}
.video-text-wrap span {
  font-size: 0.9375rem;
  color: #ff5800;
  position: relative;
  display: flex;
  text-transform: uppercase;
  line-height: 1.2;
  font-family: "BrandonGrotesque";
  font-weight: bold;
  letter-spacing: 2px;
}
.video-text-wrap span:after {
  content: "";
  height: 2px;
  background: #ff5800;
  flex: 1;
  /* align-items: flex-end; */
  margin-left: 0.625rem;
  margin-top: auto;
  margin-bottom: 2px;
  min-width: 50px;
}
.video-text-wrap h2 {
  font-size: calc(1.4375rem + 1.5vw);
  color: #4a515b;
  font-weight: bold;
  line-height: 1.2;
  margin-top: calc(1.38125rem + 1.05vw);
  margin-bottom: calc(1.40625rem + 1.25vw);
}
@media (min-width: 1800px) {
  .video-text-wrap h2 {
    font-size: 3.125rem;
  }
}
@media (min-width: 1800px) {
  .video-text-wrap h2 {
    margin-top: 2.5625rem;
  }
}
@media (min-width: 1800px) {
  .video-text-wrap h2 {
    margin-bottom: 2.8125rem;
  }
}
.video-text-wrap p {
  font-size: 1.125rem;
  color: #4a515b;
  line-height: 1.667;
  font-family: "brandon";
  font-weight: 300;
}

.our-story-hp {
  background: #f3f6fb;
  text-align: center;
  padding-top: calc(1.55rem + 2.4vw);
  padding-right: calc(1.55rem + 2.4vw);
  padding-bottom: 0;
  padding-left: calc(1.55rem + 2.4vw);
}
@media (min-width: 1800px) {
  .our-story-hp {
    padding-top: 4.25rem;
  }
}
@media (min-width: 1800px) {
  .our-story-hp {
    padding-right: 4.25rem;
  }
}
@media (min-width: 1800px) {
  .our-story-hp {
    padding-left: 4.25rem;
  }
}
.our-story-hp h2 {
  color: #ff5800;
  font-size: 0.9375rem;
  font-family: "BrandonGrotesque";
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.our-story-hp h3 {
  color: #4a515b;
}
.our-story-hp > p {
  color: #4a515b;
  font-family: "brandon";
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.667;
}
.our-story-hp .cta {
  margin-top: 220px;
}

.timeline-container {
  justify-content: space-between;
  margin-left: 16.666666666%;
  margin-right: 16.666666666%;
  position: relative;
  margin-top: calc(1.49375rem + 1.95vw);
}
@media (min-width: 1800px) {
  .timeline-container {
    margin-top: 3.6875rem;
  }
}

.timeline-container::before {
  content: "";
  width: 100%;
  height: 5px;
  background: #bdc6d6;
  position: absolute;
}

.timeline-item {
  border: 5px solid #b8c2d3;
  border-radius: 50%;
  background-color: #fff;
  width: calc(2.25rem + 8vw);
  height: calc(2.25rem + 8vw);
  flex-direction: column;
  position: relative;
  z-index: 9;
}
@media (min-width: 1800px) {
  .timeline-item {
    width: 11.25rem;
  }
}
@media (min-width: 1800px) {
  .timeline-item {
    height: 11.25rem;
  }
}
.timeline-item img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}
.timeline-item .desc {
  margin: 0;
  position: absolute;
  bottom: -160px;
  font-size: calc(1.2625rem + 0.1vw);
  font-family: "BrandonGrotesque";
  color: #4a515b;
  line-height: 1.364;
}
@media (min-width: 1800px) {
  .timeline-item .desc {
    font-size: 1.375rem;
  }
}

.timeline-item:nth-child(1) img {
  width: calc(1.5375rem + 2.3vw);
}
@media (min-width: 1800px) {
  .timeline-item:nth-child(1) img {
    width: 4.125rem;
  }
}

.timeline-item:nth-child(2) img {
  width: calc(1.65rem + 3.2vw);
}
@media (min-width: 1800px) {
  .timeline-item:nth-child(2) img {
    width: 5.25rem;
  }
}

.timeline-item:nth-child(3) img {
  width: calc(1.6rem + 2.8vw);
}
@media (min-width: 1800px) {
  .timeline-item:nth-child(3) img {
    width: 4.75rem;
  }
}

.timeline-item:nth-child(4) img {
  width: calc(1.94375rem + 5.55vw);
}
@media (min-width: 1800px) {
  .timeline-item:nth-child(4) img {
    width: 8.1875rem;
  }
}

.date-wrap {
  position: absolute;
  bottom: -41px;
  background: #ff5700;
  color: #fff;
  width: calc(1.6375rem + 3.1vw);
  height: calc(1.6375rem + 3.1vw);
  border-radius: 50%;
  flex-direction: column;
}
@media (min-width: 1800px) {
  .date-wrap {
    width: 5.125rem;
  }
}
@media (min-width: 1800px) {
  .date-wrap {
    height: 5.125rem;
  }
}
.date-wrap p {
  margin: 0;
  line-height: 1;
}
.date-wrap p:first-child {
  font-size: calc(1.275rem + 0.2vw);
  font-weight: 800;
}
@media (min-width: 1800px) {
  .date-wrap p:first-child {
    font-size: 1.5rem;
  }
}
.date-wrap p:last-child {
  font-size: 0.9375rem;
  font-weight: 700;
}

.featured-resources {
  text-align: center;
  display: none;
  background-image: url("../../assets/img/home/palm-tree-bg-blue-overlay.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: #fff;
  padding-top: calc(1.7375rem + 3.9vw);
  padding-right: calc(1.7375rem + 3.9vw);
  padding-bottom: calc(1.7375rem + 3.9vw);
  padding-left: calc(1.7375rem + 3.9vw);
}
@media (min-width: 1800px) {
  .featured-resources {
    padding-top: 6.125rem;
  }
}
@media (min-width: 1800px) {
  .featured-resources {
    padding-right: 6.125rem;
  }
}
@media (min-width: 1800px) {
  .featured-resources {
    padding-bottom: 6.125rem;
  }
}
@media (min-width: 1800px) {
  .featured-resources {
    padding-left: 6.125rem;
  }
}
.featured-resources > p {
  font-size: 1.125rem;
  font-family: "brandon";
  font-weight: 300;
  color: white;
  line-height: 1.667;
  text-align: center;
  margin-top: 0;
  margin-bottom: calc(1.4rem + 1.2vw);
}
@media (min-width: 1800px) {
  .featured-resources > p {
    margin-bottom: 2.75rem;
  }
}
.featured-resources .featured-resource-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: min-content auto;
  grid-gap: 0 38px;
}
.featured-resources .featured-resource-wrap .res-card {
  box-shadow: 1px 1.732px 9.8px 0.2px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
}
.featured-resources .featured-resource-wrap .res-card > a {
  text-decoration: none;
}
.featured-resources .featured-resource-wrap .res-card .hero {
  height: calc(2.6875rem + 11.5vw);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
@media (min-width: 1800px) {
  .featured-resources .featured-resource-wrap .res-card .hero {
    height: 15.625rem;
  }
}
.featured-resources .featured-resource-wrap .res-card .card-icon {
  width: 75px;
  height: 75px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -37.5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.featured-resources .featured-resource-wrap .res-card .card-icon img {
  display: block;
  width: 100%;
  max-width: 60%;
}
.featured-resources .featured-resource-wrap .res-card .res-content {
  background: #fff;
  padding-top: calc(1.54375rem + 2.35vw);
  padding-right: calc(1.4375rem + 1.5vw);
  padding-bottom: calc(1.4375rem + 1.5vw);
  padding-left: calc(1.4375rem + 1.5vw);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 1800px) {
  .featured-resources .featured-resource-wrap .res-card .res-content {
    padding-top: 4.1875rem;
  }
}
@media (min-width: 1800px) {
  .featured-resources .featured-resource-wrap .res-card .res-content {
    padding-right: 3.125rem;
  }
}
@media (min-width: 1800px) {
  .featured-resources .featured-resource-wrap .res-card .res-content {
    padding-bottom: 3.125rem;
  }
}
@media (min-width: 1800px) {
  .featured-resources .featured-resource-wrap .res-card .res-content {
    padding-left: 3.125rem;
  }
}
.featured-resources .featured-resource-wrap .res-card .res-content .title {
  font-size: calc(1.2625rem + 0.1vw);
  font-family: "BrandonGrotesque";
  color: #4a515b;
  line-height: 1.364;
  text-align: center;
}
@media (min-width: 1800px) {
  .featured-resources .featured-resource-wrap .res-card .res-content .title {
    font-size: 1.375rem;
  }
}
.featured-resources .featured-resource-wrap .res-card .res-content .sub-desc {
  margin-top: calc(1.3375rem + 0.7vw);
  font-size: 1.125rem;
  font-family: "brandon";
  color: #4a515b;
  font-weight: 300;
  line-height: 1.333;
  text-align: center;
}
@media (min-width: 1800px) {
  .featured-resources .featured-resource-wrap .res-card .res-content .sub-desc {
    margin-top: 2.125rem;
  }
}
.featured-resources .featured-resource-wrap .res-card .res-content p {
  margin: 0;
}
.featured-resources .featured-resource-wrap .res-card .res-content .cta {
  align-self: center;
}
.featured-resources .feat-res-cta {
  color: #fff;
  font-size: calc(1.2625rem + 0.1vw);
  margin-top: calc(1.3875rem + 1.1vw);
  font-family: "brandon";
  text-align: center;
  display: block;
}
@media (min-width: 1800px) {
  .featured-resources .feat-res-cta {
    font-size: 1.375rem;
  }
}
@media (min-width: 1800px) {
  .featured-resources .feat-res-cta {
    margin-top: 2.625rem;
  }
}

.recent-posts {
  padding-left: 16.666666666%;
  padding-right: 16.666666666%;
  padding-top: calc(1.6125rem + 2.9vw);
  padding-bottom: calc(1.8rem + 4.4vw);
  background: #f3f6fb;
  text-align: center;
}
@media (min-width: 1800px) {
  .recent-posts {
    padding-top: 4.875rem;
  }
}
@media (min-width: 1800px) {
  .recent-posts {
    padding-bottom: 6.75rem;
  }
}
.recent-posts > p {
  font-size: 0.9375rem;
  font-family: "BrandonGrotesque";
  letter-spacing: 2px;
  color: #ff5800;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2;
  margin-top: 0;
}
.recent-posts h2 {
  color: #4a515b;
}

.recent-post-wrap {
  position: relative;
  /*
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: min-content auto;
  grid-gap: 0 38px; */
  /* the slides */
  margin-top: calc(1.46875rem + 1.75vw);
}
.recent-post-wrap .slick-track {
  display: flex !important;
}
.recent-post-wrap .slick-list {
  margin-left: -38px;
  margin-right: -38px;
}
.recent-post-wrap .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  cursor: pointer;
}
.recent-post-wrap .slick-arrow i {
  color: #0e7ac4;
  font-size: 36px;
}
.recent-post-wrap .right-arrow {
  right: -50px;
}
.recent-post-wrap .left-arrow {
  left: -50px;
}
@media (min-width: 1800px) {
  .recent-post-wrap {
    margin-top: 3.4375rem;
  }
}
.recent-post-wrap .slick-slide {
  margin: 0 38px;
  height: inherit !important;
}
.recent-post-wrap .slick-slide > div {
  height: 100%;
}
.recent-post-wrap .slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 1rem 0;
  list-style-type: none;
  position: absolute;
  bottom: -55px;
  left: 0;
  right: 0;
}
.recent-post-wrap .slick-dots li {
  margin: 0 0.5rem;
}
.recent-post-wrap .slick-dots button {
  display: block;
  width: 0.938rem;
  height: 0.938rem;
  padding: 0;
  border: 1px solid #0e7ac4;
  border-radius: 100%;
  background-color: transparent;
  text-indent: -9999px;
}
.recent-post-wrap .slick-dots li.slick-active button {
  background-color: #0e7ac4;
}

.blog-anchor-wrap {
  text-decoration: none;
  cursor: pointer;
}

.post-card {
  box-shadow: 1px 1.732px 9.8px 0.2px rgba(0, 0, 0, 0.15);
  position: relative;
  background: #fff;
  height: 100%;
  display: flex !important;
  flex-direction: column;
}
.post-card .hero {
  height: 250px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.post-card .content {
  margin-bottom: 0;
  padding-top: calc(1.35rem + 0.8vw);
  padding-right: calc(1.35rem + 0.8vw);
  padding-bottom: calc(1.35rem + 0.8vw);
  padding-left: calc(1.35rem + 0.8vw);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  flex: 1;
}
@media (min-width: 1800px) {
  .post-card .content {
    padding-top: 2.25rem;
  }
}
@media (min-width: 1800px) {
  .post-card .content {
    padding-right: 2.25rem;
  }
}
@media (min-width: 1800px) {
  .post-card .content {
    padding-bottom: 2.25rem;
  }
}
@media (min-width: 1800px) {
  .post-card .content {
    padding-left: 2.25rem;
  }
}
.post-card .content p.blog-title {
  font-size: calc(1.2625rem + 0.1vw);
  font-family: "BrandonGrotesque";
  color: #4a515b;
  line-height: 1.364;
  text-align: center;
}
@media (min-width: 1800px) {
  .post-card .content p.blog-title {
    font-size: 1.375rem;
  }
}
.post-card .content p.blog-excerpt {
  font-size: 1.125rem;
  font-family: "brandon";
  color: #4a515b;
  line-height: 1.333;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-weight: 300;
}
.post-card .content .cta {
  align-self: center;
  flex: 0;
}

.post-date {
  font-weight: 300;
  font-size: 1rem;
  position: absolute;
  bottom: -60px;
  font-family: "brandon";
  font-weight: 300;
  color: #4a515b;
  left: 50%;
  transform: translateX(-50%);
}

.recent-post-wrap .slick-slide {
  margin-bottom: 60px;
}

.loanapp-band {
  color: #fff;
  background-image: url("../../assets/img/new-homepage/cap_extended_large.png");
  position: relative;
  /* padding-top: 23.33333333333%; */
  height: 420px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.loanapp-band .text-wrap {
  position: absolute;
  right: calc(3.14375rem + 15.15vw);
  top: 50%;
  transform: translateY(-50%);
  width: calc(4.40625rem + 25.25vw);
}
@media (min-width: 1800px) {
  .loanapp-band .text-wrap {
    right: 20.1875rem;
  }
}
@media (min-width: 1800px) {
  .loanapp-band .text-wrap {
    width: 32.8125rem;
  }
}
.loanapp-band h2 {
  font-size: calc(1.575rem + 2.6vw);
  margin: 0;
  text-transform: uppercase;
  font-family: "BrandonGrotesque";
}
@media (min-width: 1800px) {
  .loanapp-band h2 {
    font-size: 4.5rem;
  }
}
.loanapp-band p {
  font-family: "brandon";
  font-weight: 300;
}
.loanapp-band .cta-wrap {
  display: flex;
}
.loanapp-band .cta-wrap a:first-child {
  /* margin-right: 15px; */
}
.loanapp-band .cta-wrap a:last-child {
  margin-left: 15px;
}
.loanapp-band .cta-wrap .cta {
  flex: 50%;
}

@media only screen and (max-width: 1500px) {
  .card-grid,
.recent-posts {
    padding-left: 8%;
    padding-right: 8%;
  }
}
@media only screen and (max-device-width: 1024px) {
  .card-item {
    align-items: center;
  }

  .card-grid {
    grid-template-columns: 1fr;
    grid-template-rows: min-content auto;
    grid-gap: 36px 0;
  }

  .featured-resources .featured-resource-wrap {
    grid-template-columns: 1fr;
    grid-template-rows: min-content auto;
    grid-gap: 36px 0;
  }

  .recent-post-wrap {
    grid-template-columns: 1fr;
    grid-template-rows: min-content auto;
    grid-gap: 36px 0;
  }

  .card-grid, .res-card, .post-card {
    width: 75%;
    margin: 0 auto;
  }
}
@media only screen and (max-device-width: 767px) {
  .recent-post-wrap .slick-dots {
    bottom: -66px;
  }

  .recent-posts {
    padding-bottom: 85px;
  }

  .hero-text .cta-wrap {
    width: 100%;
    text-align: center;
    margin-top: 0;
  }
  .hero-text .cta-wrap .hero-cta {
    margin-top: 0;
  }

  .date-wrap p:last-child {
    display: none;
  }

  .video-text-wrap {
    padding-left: 0;
    text-align: center;
  }
  .video-text-wrap span:before {
    content: "";
    height: 2px;
    background: #ff5800;
    flex: 1;
    margin-top: auto;
    margin-bottom: 2px;
    margin-right: 0.625rem;
  }

  .video-inline {
    flex-direction: column;
    padding: 36px;
  }

  .video-player {
    width: 100%;
    background-position: center center;
    margin-bottom: 36px;
  }

  .video-player::before {
    content: unset;
  }

  .loanapp-band .text-wrap {
    display: block;
    right: 0;
    left: 0;
    width: 80%;
    margin: 0 auto;
    text-align: center;
  }

  .featured-resources {
    display: none;
  }

  .our-story-hp .cta {
    margin-top: 20px;
  }

  .timeline-container {
    margin-left: 0;
    margin-right: 0;
    flex-direction: column;
  }

  .timeline-container::before {
    content: unset;
    width: 5px;
    height: 100%;
  }

  .timeline-item {
    width: 300px;
    height: 300px;
    margin-bottom: 200px;
  }
  .timeline-item img:nth-child(1), .timeline-item img:nth-child(2), .timeline-item img:nth-child(3), .timeline-item img:nth-child(4) {
    width: 50%;
  }
  .timeline-item .desc {
    font-size: 36px;
    bottom: -185px;
    /* background: #f3f6fb; */
  }

  .date-wrap {
    width: 125px;
    height: 125px;
    bottom: -70px;
  }
}