@mixin rfs-padding($padT, $padR, $padB, $padL) {
    @include padding-top($padT);
    @include padding-right($padR);
    @include padding-bottom($padB);
    @include padding-left($padL);
}

@mixin limit-lines($nLines) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $nLines;
    -webkit-box-orient: vertical;
}