body {
    display: block !important;
}

.home-banner {
    background: url('../../assets/img/home/new-home-hero.png') no-repeat;
    background-size: cover;
    width: 100%;
    height: 902px;
}

.hero-text {
    text-align: center;
    padding-top: 105px;
    h1 {
        @include font-size(48px);
        font-family: "BrandonGrotesque";
        color: rgb(74, 81, 91);
        line-height: 1.2;
    }
    p {
        @include font-size(24px);
        font-family: "brandon";
        color: rgb(74, 81, 91);
        line-height: 1.2;
        text-align: center;
    }
    .cta-wrap {
        width: 100%;
        text-align: center;
        /* margin-top: 59px; */
        a:first-child {
            /* margin-right: 20px; */
        }
    }
}

.header-wrap {
    width: 66.667%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.intro-wrap {
    background: #1a4072;
    color: #fff;
    @extend %flex-center;
    flex-direction: column;
    @include rfs-padding(70px,
    70px,
    0,
    70px);
    padding-bottom: 470px;
    p {
        @include margin-top(38px);
        @include font-size(24px);
    }
}

.testimonial-slider-wrap {
    $paddingOffset: 400px;
    width: 100%;
    position: relative;
    background: #f3f6fb;
    .testimonial-slider {
        margin-top: -$paddingOffset;
        @include padding-bottom(60px);
        .slick-track {
            display: flex !important;
            padding-bottom: 13px; /* Prevent cut off of item box shadow */
        }
        .slick-slide {
            >div, .slide {
                height: 100%;
            }
            height: inherit !important;
        }
    }
    .tst-slider-item {
        margin: 0 15px;
        box-shadow: 1px 1.732px 9.8px 0.2px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: column;
        height: 100%;
        @include margin-bottom(15px);
        .hero {
            // width: 430px;
            height: 280px;
            background-position: center top;
            background-size: cover;
        }
        .content {
            @include rfs-padding(36px,
            36px,
            36px,
            36px);
            background: #fff;
            margin-bottom: 0;
            flex: 1;
        }
        .words {
            @include font-size(24px);
            color: #4a515b;
            line-height: 1.25;
            @include margin-bottom(20px);
            font-family: "BrandonGrotesque";
            @include limit-lines(1);
        }
        .desc {
            @include font-size(18px);
            font-family: "Brandon_light";
            color: rgb(74, 81, 91);
            line-height: 1.333;
            text-align: left;
            @include limit-lines(7);
        }
        .name {
            @include font-size(18px);
            font-family: "BrandonGrotesque";
            color: #353535;
            font-weight: bold;
            line-height: 1.2;
            margin: 25px auto 12px;
        }
    }
    .slick-list {
        /* padding: 0 12.5%; */
    }
}

.testimonial-slider-wrap::before {
    content: '';
    background: #1a4072;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 330px;
}

.card-wrap {
    background: #f3f6fb;
    color: #4a515b;
    text-align: center;
    @include rfs-padding(0,
            68px,
            0,
            68px);
    h2 {
        color: rgb(74, 81, 91);
    }
    >p {
        @include margin-bottom(57px);
    }
}

.card-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: min-content auto;
    grid-gap: 38px 38px;
    padding-left: 16.611111111%;
    padding-right: 16.611111111%;
    @include padding-bottom(89px);
}

.card-item {
    box-shadow: 1px 1.732px 9.8px 0.2px rgba(0, 0, 0, 0.15);
    @include rfs-padding(50px,
    44px,
    50px,
    44px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;
    img {
        @include margin-bottom(35px);
        /*width: 100%;
@include rfs(110px,
max-width);*/
        margin: 0 auto;
        height: 120px;
    }
    >p.card-title {
        color: #4a515b;
        @include font-size(24px);
        font-weight: bold;
        @include margin-bottom(24px);
    }
    >p.card-desc {
        color: #4a515b;
        @include font-size(18px);
        margin: 0 auto;
        font-family: "brandon";
        font-weight: 300;
        line-height: 1.333;
    }
    .cta {
        flex: 0;
    }
}

.video-inline {
    display: flex;
    padding: 0 16.277777777% 0 19.444444444%;
    @include padding-bottom(102px);
    @include padding-top(102px);
}

.video-player {
    background: url("../../assets/img/boots-to-roots/mark_peer_default.png") no-repeat;
    // width: 1025px;
    // height: 684px;
    background-size: contain;
    @include rfs(640px,
    width);
    @include rfs(427px,
    height);
    // width: 640px;
    // height: 427px;
    margin: 0 auto;
    cursor: pointer;
    position: relative;
    -webkit-transition: 0.2s all ease-in;
    -o-transition: 0.2s all ease-in;
    transition: 0.2s all ease-in;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    // flex-basis: 640px;
    @include rfs(640px,
    flex-basis);
}

.video-player::before {
    content: '';
    position: absolute;
    border: 11px solid #1a4072;
    top: 50px;
    left: -39px;
    right: 50px;
    bottom: -39px;
    z-index: -1;
}

.video-player .video {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.video-player .video iframe {
    width: 100%;
    height: 100%;
}

.preload {
    background: url("../../assets/img/boots-to-roots/mark_peer_hover.png") no-repeat -9999999px -99999999px;
}

.video-player:hover {
    background-image: url("../../assets/img/boots-to-roots/mark_peer_hover.png");
    cursor: pointer;
}

.video-text-wrap {
    @include padding-left(104px);
    span {
        @include font-size(15px);
        color: #ff5800;
        position: relative;
        display: flex;
        text-transform: uppercase;
        line-height: 1.2;
        font-family: "BrandonGrotesque";
        font-weight: bold;
        letter-spacing: 2px;
    }
    span:after {
        content: '';
        height: 2px;
        background: #ff5800;
        flex: 1;
        /* align-items: flex-end; */
        @include margin-left(10px);
        margin-top: auto;
        margin-bottom: 2px;
        // width: 100%;
        min-width: 50px;
    }
    h2 {
        @include font-size(50px);
        color: rgb(74, 81, 91);
        font-weight: bold;
        line-height: 1.2;
        @include margin-top(41px);
        @include margin-bottom(45px);
    }
    p {
        @include font-size(18px);
        color: rgb(74, 81, 91);
        line-height: 1.667;
        font-family: "brandon";
        font-weight: 300;
    }
}

$descOffset: 160px;
.our-story-hp {
    background: #f3f6fb;
    text-align: center;
    @include rfs-padding(68px,
    68px,
    0,
    68px);
    h2 {
        color: #ff5800;
        @include font-size(15px);
        font-family: "BrandonGrotesque";
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 2px;
    }
    h3 {
        color: #4a515b;
    }
    >p {
        color: #4a515b;
        font-family: 'brandon';
        font-weight: 300;
        @include font-size(18px);
        line-height: 1.667;
    }
    .cta {
        margin-top: $descOffset + 60px;
    }
}

.timeline-container {
    @extend %flex-center;
    justify-content: space-between;
    margin-left: 16.666666666%;
    margin-right: 16.666666666%;
    position: relative;
    @include margin-top(59px);
}

.timeline-container::before {
    content: '';
    width: 100%;
    height: 5px;
    background: #bdc6d6;
    position: absolute;
}

.timeline-item {
    border: 5px solid rgb(184, 194, 211);
    border-radius: 50%;
    background-color: #fff;
    @include rfs(180px,
    width);
    @include rfs(180px,
    height);
    @extend %flex-center;
    flex-direction: column;
    position: relative;
    z-index: 9;
    img {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
    }
    .desc {
        margin: 0;
        position: absolute;
        bottom: -$descOffset;
        @include font-size(22px);
        font-family: "BrandonGrotesque";
        color: rgb(74, 81, 91);
        line-height: 1.364;
    }
}

.timeline-item:nth-child(1) {
    img {
        @include rfs(66px,
        width);
    }
}

.timeline-item:nth-child(2) {
    img {
        @include rfs(84px,
        width);
    }
}

.timeline-item:nth-child(3) {
    img {
        @include rfs(76px,
        width);
    }
}

.timeline-item:nth-child(4) {
    img {
        @include rfs(131px,
        width);
    }
}

.date-wrap {
    $size: 82px;
    $offset: $size/2;
    position: absolute;
    bottom: -$offset;
    background: #ff5700;
    color: #fff;
    @include rfs($size,
    width);
    @include rfs($size,
    height);
    border-radius: 50%;
    @extend %flex-center;
    flex-direction: column;
    p {
        margin: 0;
        line-height: 1;
    }
    p:first-child {
        @include font-size(24px);
        font-weight: 800;
    }
    p:last-child {
        @include font-size(15px);
        font-weight: 700;
    }
}

.featured-resources {
    text-align: center;
    display: none;
    background-image: url('../../assets/img/home/palm-tree-bg-blue-overlay.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    color: #fff;
    @include rfs-padding(98px,
    98px,
    98px,
    98px);
    >p {
        @include font-size(18px);
        font-family: "brandon";
        font-weight: 300;
        color: rgb(255, 255, 255);
        line-height: 1.667;
        text-align: center;
        margin-top: 0;
        @include margin-bottom(44px);
    }
    .featured-resource-wrap {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: min-content auto;
        grid-gap: 0 38px;
        .res-card {
            box-shadow: 1px 1.732px 9.8px 0.2px rgba(0, 0, 0, 0.15);

            > a {
                text-decoration: none;
            }

            display: flex;
            flex-direction: column;
            .hero {
                @include rfs(250px,
                height);
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;
            }
            .card-icon {
                $iconSize: 75px;
                width: $iconSize;
                height: $iconSize;
                background: #fff;
                border-radius: 50%;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -($iconSize/2);
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    display: block;
                    width: 100%;
                    max-width: 60%;
                }
            }
            .res-content {
                //height: 324px;
                background: #fff;
                @include rfs-padding(67px,
                50px,
                50px,
                50px);
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .title {
                    @include font-size(22px);
                    font-family: "BrandonGrotesque";
                    color: rgb(74, 81, 91);
                    line-height: 1.364;
                    text-align: center;
                }
                .sub-desc {
                    @include margin-top(34px);
                    @include font-size(18px);
                    font-family: "brandon";
                    color: rgb(74, 81, 91);
                    font-weight: 300;
                    line-height: 1.333;
                    text-align: center;
                }
                p {
                    margin: 0;
                }
                .cta {
                    align-self: center;
                }
            }
        }
    }
    .feat-res-cta {
        color: #fff;
        @include font-size(22px);
        @include margin-top(42px);
        font-family: 'brandon';
        text-align: center;
        display: block;
    }
}

.recent-posts {
    padding-left: 16.666666666%;
    padding-right: 16.666666666%;
    @include padding-top(78px);
    @include padding-bottom(108px);
    background: #f3f6fb;
    text-align: center;
    >p {
        @include font-size(15px);
        font-family: "BrandonGrotesque";
        letter-spacing: 2px;
        color: rgb(255, 88, 0);
        font-weight: bold;
        text-transform: uppercase;
        line-height: 1.2;
        margin-top: 0;
    }
    h2 {
        color: #4a515b;
    }
}


.recent-post-wrap {
    $postItemSpace: 38px;
    position: relative;
    /*
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: min-content auto;
    grid-gap: 0 38px; */
    /* the slides */
    .slick-track {
        display: flex !important;
    }
    .slick-list {
        margin-left: -$postItemSpace;
        margin-right: -$postItemSpace;
    }
    .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;
        cursor: pointer;
        i {
            color: #0e7ac4;
            font-size: 36px;
        }
    }
    $arrowOffset: 50px;
    .right-arrow {
        right: -$arrowOffset;
    }
    .left-arrow {
        left: -$arrowOffset;
    }
    @include margin-top(55px);
    .slick-slide {
        margin: 0 $postItemSpace;
        height: inherit !important;
        > div {
            height: 100%;
        }
    }
    .slick-dots {
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 1rem 0;
        list-style-type: none;
        position: absolute;
        bottom: -55px;
        left: 0;
        right: 0;
        li {
            margin: 0 0.5rem;
        }
        button {
            display: block;
            width: .938rem;
            height: .938rem;
            padding: 0;
            border: 1px solid #0e7ac4;
            border-radius: 100%;
            background-color: transparent;
            text-indent: -9999px;
        }
        li.slick-active button {
            background-color: #0e7ac4;
        }
    }
}

.blog-anchor-wrap {
    text-decoration: none;
    cursor: pointer;
}

.post-card {
    box-shadow: 1px 1.732px 9.8px 0.2px rgba(0, 0, 0, 0.15);
    position: relative;
    background: #fff;
    height: 100%;
    display: flex !important;
    flex-direction: column;
    .hero {
        height: 250px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .content {
        margin-bottom: 0;
        @include rfs-padding(36px,
        36px,
        36px,
        36px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #fff;
        flex: 1;
        p.blog-title {
            @include font-size(22px);
            font-family: "BrandonGrotesque";
            color: rgb(74, 81, 91);
            line-height: 1.364;
            text-align: center;
        }
        p.blog-excerpt {
            @include font-size(18px);
            font-family: "brandon";
            color: rgb(74, 81, 91);
            line-height: 1.333;
            text-align: center;
            @include limit-lines(3);
            font-weight: 300;
        }
        .cta {
            align-self: center;
            flex: 0;
        }
    }
}

$slideDateOffset: 60px;
.post-date {
    font-weight: 300;
    @include font-size(16px);
    position: absolute;
    bottom: -$slideDateOffset;
    font-family: 'brandon';
    font-weight: 300;
    color: #4a515b;
    left: 50%;
    transform: translateX(-50%);
}

.recent-post-wrap .slick-slide {
    margin-bottom: $slideDateOffset;
}

.loanapp-band {
    color: #fff;
    background-image: url('../../assets/img/new-homepage/cap_extended_large.png');
    position: relative;
    /* padding-top: 23.33333333333%; */
    height: 420px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    .text-wrap {
        position: absolute;
        @include rfs(323px,
        right);
        top: 50%;
        transform: translateY(-50%);
        @include rfs(525px,
        width);
    }
    h2 {
        @include font-size(72px);
        margin: 0;
        text-transform: uppercase;
        font-family: 'BrandonGrotesque';
    }
    p {
        font-family: 'brandon';
        font-weight: 300;
    }
    .cta-wrap {
        display: flex;
        a:first-child {
            /* margin-right: 15px; */
        }
        a:last-child {
            margin-left: 15px;
        }
        .cta {
            flex: 50%;
        }
    }
}

@media only screen and (max-width: 1500px) {
    .card-grid,
    .recent-posts {
        padding-left: 8%;
        padding-right: 8%;
    }
}

@media only screen and (max-device-width: 1024px) {
    .card-item {
        align-items: center;
    }
    .card-grid {
        grid-template-columns: 1fr;
        grid-template-rows: min-content auto;
        grid-gap: 36px 0;
    }
    .featured-resources {
        .featured-resource-wrap {
            grid-template-columns: 1fr;
            grid-template-rows: min-content auto;
            grid-gap: 36px 0;
        }
    }
    .recent-post-wrap {
        grid-template-columns: 1fr;
        grid-template-rows: min-content auto;
        grid-gap: 36px 0;
    }
    .card-grid, .res-card, .post-card {
        width: 75%;
        margin: 0 auto;
    }
}

@media only screen and (max-device-width: 767px) {
    .recent-post-wrap {
        .slick-dots {
            bottom: -66px;
        }
    }
    .recent-posts {
        padding-bottom: 85px;
    }
    .hero-text {
        .cta-wrap {
            width: 100%;
            text-align: center;
            margin-top: 0;
            .hero-cta {
                margin-top: 0;
            }
        }
    }
    .date-wrap {

        p:last-child {
            display: none;
        }
    }
    .video-text-wrap {
        padding-left: 0;
        text-align: center;

        span:before {
            content: "";
            height: 2px;
            background: #ff5800;
            flex: 1;
            margin-top: auto;
            margin-bottom: 2px;
            margin-right: 0.625rem;
        }
    }
    .video-inline {
        flex-direction: column;
        padding: 36px;
    }
    .video-player {
        width: 100%;
        background-position: center center;
        margin-bottom: 36px;
    }
    .video-player::before {
        content: unset;
    }

    .loanapp-band .text-wrap {
        display: block;
        right: 0;
        left: 0;
        width: 80%;
        margin: 0 auto;
        text-align: center;
    }
    .loanapp-band .cta-wrap .cta {

    }
    .featured-resources {
        display: none;
    }
    .our-story-hp {
        .cta {
            margin-top: 20px;
        }
    }
    .timeline-container {
        margin-left: 0;
        margin-right: 0;
        flex-direction: column;
    }
    .timeline-container::before {
        content: unset;
        width: 5px;
        height: 100%;
    }
    .timeline-item {
        width: 300px;
        height: 300px;
        margin-bottom: 200px;
        img:nth-child(1), img:nth-child(2), img:nth-child(3), img:nth-child(4) {
            width: 50%;
        }
        .desc {
            font-size: 36px;
            bottom: -185px;
            /* background: #f3f6fb; */
        }
    }
    .date-wrap {
        width: 125px;
        height: 125px;
        bottom: -70px;
    }

}