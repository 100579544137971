@font-face {
    font-family: 'brandon';
    src: url('../../assets/css/fonts/brandon/brandon_bld-webfont.eot');
    src: url('../../assets/css/fonts/brandon/brandon_bld-webfont.eot?#iefix') format('embedded-opentype'), url('../../assets/css/fonts/brandon/brandon_bld-webfont.woff') format('woff'), url('../../assets/css/fonts/brandon/brandon_bld-webfont.ttf') format('truetype'), url('../../assets/css/fonts/brandon/brandon_bld-webfont.svg#brandon_grotesquebold') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SourceSansPro';
    src: url('../../assets/css/fonts/SourceSansPro-Regular.otf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon_light';
    src: url('../../assets/css/fonts/brandon/Brandon_light.otf'), url('../../assets/css/fonts/brandon/Brandon_light.otf') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'BrandonGrotesque';
    src: url('../../assets/css/fonts/brandon/Brandon_bld.otf'), url('../../assets/css/fonts/brandon/Brandon_bld.otf') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'brandon';
    src: url('../../assets/css/fonts/brandon/brandon_blk-webfont.eot');
    src: url('../../assets/css/fonts/brandon/brandon_blk-webfont.eot?#iefix') format('embedded-opentype'), url('../../assets/css/fonts/brandon/brandon_blk-webfont.woff') format('woff'), url('../../assets/css/fonts/brandon/brandon_blk-webfont.ttf') format('truetype'), url('../../assets/css/fonts/brandon/brandon_blk-webfont.svg#brandon_grotesqueblack') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'brandon';
    src: url('../../assets/css/fonts/brandon/brandon_reg-webfont.eot');
    src: url('../../assets/css/fonts/brandon/brandon_reg-webfont.eot?#iefix') format('embedded-opentype'), url('../../assets/css/fonts/brandon/brandon_reg-webfont.woff') format('woff'), url('../../assets/css/fonts/brandon/brandon_reg-webfont.ttf') format('truetype'), url('../../assets/css/fonts/brandon/brandon_reg-webfont.svg#brandon_grotesque_regularRg') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'sourceSemiBold';
    src: url('../../assets/css/fonts/source/SourceSansPro-Semibold.ttf');
    src: url('../../assets/css/fonts/source/SourceSansPro-Semibold.ttf?#iefix') format('embedded-opentype'), url('../../assets/css/fonts/source/SourceSansPro-Semibold.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'sourceSemiItallic';
    src: url('../../assets/css/fonts/source/SourceSansPro-SemiboldItalic.ttf');
    src: url('../../assets/css/fonts/source/SourceSansPro-SemiboldItalic.ttf?#iefix') format('embedded-opentype'), url('../../assets/css/fonts/source/SourceSansPro-SemiboldItalic.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'source';
    src: url('../../assets/css/fonts/source/sourcesanspro-bold-webfont.eot');
    src: url('../../assets/css/fonts/source/sourcesanspro-bold-webfont.eot?#iefix') format('embedded-opentype'), url('../../assets/css/fonts/source/sourcesanspro-bold-webfont.woff') format('woff'), url('../../assets/css/fonts/source/sourcesanspro-bold-webfont.ttf') format('truetype'), url('../../assets/css/fonts/source/sourcesanspro-bold-webfont.svg#source_sans_probold') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'source';
    src: url('../../assets/css/fonts/source/sourcesanspro-italic-webfont.eot');
    src: url('../../assets/css/fonts/source/sourcesanspro-italic-webfont.eot?#iefix') format('embedded-opentype'), url('../../assets/css/fonts/source/sourcesanspro-italic-webfont.woff') format('woff'), url('../../assets/css/fonts/source/sourcesanspro-italic-webfont.ttf') format('truetype'), url('../../assets/css/fonts/source/sourcesanspro-italic-webfont.svg#source_sans_proitalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'source';
    src: url('../../assets/css/fonts/source/sourcesanspro-regular-webfont.eot');
    src: url('../../assets/css/fonts/source/sourcesanspro-regular-webfont.eot?#iefix') format('embedded-opentype'), url('../../assets/css/fonts/source/sourcesanspro-regular-webfont.woff') format('woff'), url('../../assets/css/fonts/source/sourcesanspro-regular-webfont.ttf') format('truetype'), url('../../assets/css/fonts/source/sourcesanspro-regular-webfont.svg#source_sans_proregular') format('svg');
    font-weight: 300;
    font-style: normal;
}


/* font-family: "MyriadProRegular"; */

@font-face {
    font-family: "MyriadProRegular";
    src: url("../../assets/css/fonts/MyriadProRegular/MyriadProRegular.eot");
    src: url("../../assets/css/fonts/MyriadProRegular/MyriadProRegular.eot?#iefix")format("embedded-opentype"), url("../../assets/css/fonts/MyriadProRegular/MyriadProRegular.woff") format("woff"), url("../../assets/css/fonts/MyriadProRegular/MyriadProRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}


/* font-family: "MyriadProBold"; */

@font-face {
    font-family: "MyriadProBold";
    src: url("../../assets/css/fonts/MyriadProBold/MyriadProBold.eot");
    src: url("../../assets/css/fonts/MyriadProBold/MyriadProBold.eot?#iefix")format("embedded-opentype"), url("../../assets/css/fonts/MyriadProBold/MyriadProBold.woff") format("woff"), url("../../assets/css/fonts/MyriadProBold/MyriadProBold.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}


/* font-family: "MyriadProItalic"; */

@font-face {
    font-family: "MyriadProItalic";
    src: url("../../assets/css/fonts/MyriadProItalic/MyriadProItalic.eot");
    src: url("../../assets/css/fonts/MyriadProItalic/MyriadProItalic.eot?#iefix")format("embedded-opentype"), url("../../assets/css/fonts/MyriadProItalic/MyriadProItalic.woff") format("woff"), url("../../assets/css/fonts/MyriadProItalic/MyriadProItalic.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}


/* font-family: "MyriadProSemiBold"; */

@font-face {
    font-family: "MyriadProSemiBold";
    src: url("../../assets/css/fonts/MyriadProSemiBold/MyriadProSemiBold.eot");
    src: url("../../assets/css/fonts/MyriadProSemiBold/MyriadProSemiBold.eot?#iefix")format("embedded-opentype"), url("../../assets/css/fonts/MyriadProSemiBold/MyriadProSemiBold.woff") format("woff"), url("../../assets/css/fonts/MyriadProSemiBold/MyriadProSemiBold.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}


/* font-family: "MyriadProBoldItalic"; */

@font-face {
    font-family: "MyriadProBoldItalic";
    src: url("../../assets/css/fonts/MyriadProBoldItalic/MyriadProBoldItalic.eot");
    src: url("../../assets/css/fonts/MyriadProBoldItalic/MyriadProBoldItalic.eot?#iefix")format("embedded-opentype"), url("../../assets/css/fonts/MyriadProBoldItalic/MyriadProBoldItalic.woff") format("woff"), url("../../assets/css/fonts/MyriadProBoldItalic/MyriadProBoldItalic.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}


/* font-family: "MyriadProSemiBoldItalic"; */

@font-face {
    font-family: "MyriadProSemiBoldItalic";
    src: url("../../assets/css/fonts/MyriadProSemiBoldItalic/MyriadProSemiBoldItalic.eot");
    src: url("../../assets/css/fonts/MyriadProSemiBoldItalic/MyriadProSemiBoldItalic.eot?#iefix")format("embedded-opentype"), url("../../assets/css/fonts/MyriadProSemiBoldItalic/MyriadProSemiBoldItalic.woff") format("woff"), url("../../assets/css/fonts/MyriadProSemiBoldItalic/MyriadProSemiBoldItalic.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}