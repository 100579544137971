body {
    font: 1.2em "Source", sans-serif;
}


/* CTA Styles */

.orange-cta {
    text-transform: uppercase;
    cursor: pointer;
    color: #ff5800;
    border: 2px solid #ff5800;
    font-weight: bold;
    border-radius: 3px;
    text-decoration: none;
    display: inline-flex;
    flex: 1;
    @include font-size(15px);
    @include rfs-padding(18px, 23px, 18px, 23px);
    @extend %flex-center;
    @include margin-top(38px);
    transition: all 0.2s ease-in-out;
    background: #fff;
}

.orange-cta:hover {
    background: #ff5800;
    color: #fff;
}

.orange-cta-invert {
    text-transform: uppercase;
    cursor: pointer;
    color: #fff;
    border: 2px solid #ff5800;
    font-weight: bold;
    border-radius: 3px;
    text-decoration: none;
    display: inline-flex;
    flex: 1;
    @include font-size(15px);
    @include rfs-padding(18px, 23px, 18px, 23px);
    @extend %flex-center;
    @include margin-top(38px);
    transition: all 0.2s ease-in-out;
    background: #ff5800;
}

.orange-cta-invert:hover {
    background: #fff;
    color: #ff5800;
}

.hero-cta {
    cursor: pointer;
    color: #fff;
    border: 2px solid #ff5800;
    font-weight: bold;
    border-radius: 8px;
    text-decoration: none;
    display: inline-flex;
    flex: 1;
    @include font-size(18px);
    @include rfs-padding(24px, 18px, 24px, 18px);
    @extend %flex-center;
    @include margin-top(38px);
    transition: all 0.2s ease-in-out;
    background: #ff5800;
    img {
        margin-right: 7px;
    }
}

.hero-cta:hover {
    /* background: #fff; */
    background: #ff7e00;
    border: 2px solid #ff7e00;
    /* color: #ff5800; */
}

.blue-cta {
    text-transform: uppercase;
    cursor: pointer;
    color: #0e7ac4;
    border: 2px solid #0e7ac4;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    flex-shrink: 0;
    flex: 0;
    @include font-size(15px);
    @include rfs-padding(18px, 34px, 18px, 34px);
    @extend %flex-center;
    @include margin-top(38px);
    transition: all 0.2s ease-in-out;
    border-radius: 3px;
    background: #fff;
}

.blue-cta:hover {
    color: #fff;
    border: 2px solid #ff5800;
    background: #ff5800;
}

.blue-cta-invert {
    text-transform: uppercase;
    cursor: pointer;
    color: #fff;
    border: 2px solid #0e7ac4;
    font-weight: bold;
    text-decoration: none;
    display: inline-flex;
    flex: 1 0 auto;
    @include font-size(15px);
    @include rfs-padding(18px, 34px, 18px, 34px);
    @extend %flex-center;
    @include margin-top(38px);
    transition: all 0.2s ease-in-out;
    border-radius: 3px;
    background: #0e7ac4;
}

.blue-cta-invert:hover {
    background: #fff;
    color: #0e7ac4;
}

@media only screen and (max-device-width: 767px) {
    .blue-cta-invert {
        width: 100%;
        box-sizing: border-box;
    }
;
}